// src/data.js

export const books = [
  ["HeyGen", "No relevant snippet found", "https://heygen.com/"],
  ["Gamma", "Gamma is a new medium for presenting ideas, powered by AI. It allows you to create and share beautiful and dynamic content without any design or coding skills, and integrates with Notion, Canva, and other tools.", "https://gamma.app/"],
  ["TypeSet", "What is the significance of higher-dimensional algebra? Chat with PDF. Get all answers backed by citations. Literature Review. Discover new papers for your research. AI Writer. Use AI suggestions to expand notes to paragraphs. Find Concepts. Discover concepts from 285M research papers. Paraphraser. Increase fluency of your content.", "https://typeset.io/"],
  ["Repurpose.io", "Ref A: 66c9f59b3a0f4fa4a1307b4fba438124 Ref B: BNZEEAP00016A4D Ref C: 2024-08-24T15:00:43Z", "https://repurpose.io/"],
  ["Videodeck", "Our video-as-a-service solution offers a subscription that offers you weekly videos or one-time product videos that tell your product's story. An antidote to the outdated video agency model. Under 10-day delivery for complex videos. Affordable and transparent pricing.", "https://www.videodeck.com/"],
  ["LALAL.AI", "A next-generation vocal remover and music source separation service for fast, easy, and precise stem extraction. Remove vocal, instrumental, drums, bass, piano, electric guitar, acoustic guitar, and synthesizer tracks without quality loss.", "https://lalal.ai/"],
  ["Trademark Engine", "Get your trademark registered in just 3 easy steps using our simple online questionnaire. 1. Search for Availability. Check to see if your trademark is already being used. With both services, we’ll help you see if your trademark is already filed. 2. Answer a Few Questions.", "https://www.trademarkengine.com/"],
  ["Ideogram", "Ideogram is a free-to-use AI tool that generates realistic images, posters, logos, and more.", "https://ideogram.io/"],
  ["Funnel", "The industry's largest collection of marketing connectors. Access your sales and marketing data from any of your apps or platforms at any time in just a few clicks. 500+ silky smooth connections. Always reliable and secure. No coding necessary.", "https://funnel.io/"],
  ["FlexClip", "FlexClip is a free online video editor and video maker that you can use to create videos with text, music, animations, and more effects. No video editing skills required. Try it now!", "https://www.flexclip.com/"],
  ["AiGrow", "AiGrow is your all-in-one Instagram solution, offering content creation, genuine follower growth, guaranteed engagement, and seamless direct messaging to elevate your Instagram presence. Join us today to unlock the full potential of your Instagram account.", "https://aigrow.me/"],
  ["Journey.io", "Insightly and hopefully — you. Reach prospects and share relevant files, links, and resources without worrying about deliverability. Get visibility into what prospects are doing and offer up timely, relevant support throughout the sales journey.", "https://journey.io/"],
  ["Opus Clip", "Post one clip every day for free. Opus Clip turns long videos into high-quality viral clips for you to share on TikTok, YouTube Shorts, and Reels to increase social media reach.", "https://www.opus.pro/"],
  ["Leiapix", "LeiaPix Converter lets you turn any of your favorite images into stunning Depth Animations.", "convert.leiapix.com"],
  ["Caption AI", "Unlock global potential. Meet AI Creator, the world’s first 3D avatar designed for. Just select an editing style and watch as AI edits your video in real-time. Translate your voice into 28+ languages, with synced lip.", "http://caption.ai"],
  ["Hypefury", "Hypefury - Schedule & Automate Social Media Marketing. Your personal assistant to grow & monetize your 𝕏 (Twitter) audience. We picked the best techniques used by 𝕏 OG’s, and turned them into a powerful app: Create new content seamlessly. Grow your audience. Grow your email list. Sell more products.", "https://hypefury.com/"],
  ["Testimonial.to", "With Testimonial, in minutes, you can collect and display video testimonials and other types of testimonials from your customers with no need for a developer or website hosting.", "https://testimonial.to/"],
  ["Taplio", "Taplio offers a wide range of features that help you grow, nurture and convert your LinkedIn network. From turning likes and comments into actual relationships, to giving you access to millions of leads with relevant filters, we got you covered.", "https://taplio.com/"],
  ["Tome", "Tome's sales-specific AI is better at business intelligence, buyer research, and value articulation than general purpose models. A second brain, engineered for sales.", "https://www.tome.app/"],
  ["Audacity", "Audacity is the world's most popular audio editing and recording app. Download Audacity 3.6.1. Download without Muse Hub. Produce music. Produce podcasts. Take total control of your sound.", "https://wave.video/tools/audio-editing/video-to-mp3"],
  ["Chatfuel", "Maximize your revenue with a leading marketing & sales platform for eCommerce: create AI chatbots for WhatsApp, Instagram, Facebook, and websites.", "https://chatfuel.com/ai"],
  ["Later", "Social media management made easy. Crush your social goals faster with Later Social™. Automate daily social tasks and turn followers into customers with Link in Bio — all in one app. Start Your Free Trial.", "https://later.com/"],
  ["Hootsuite", "Naptime awaits. With Hootsuite, you can design, schedule, and publish content for all your networks from one dashboard. Create dazzling posts fast with Canva templates, have AI write your captions and hashtags for you, then get approval with seamless workflows.", "https://hootsuite.com/"],
  ["Sprout Social", "A powerful solution for social media management. Our all-in-one social media management platform unlocks the full potential of social to transform not just your marketing strategy—but every area of your organization.", "https://sproutsocial.com/"],
  ["SocialOomph", "Keep your brand voice consistent by scheduling and publishing content ahead of time. Streamline your workflow and be more efficient with your time.", "https://www.socialoomph.com/"],
  ["Metricool", "Manage your social media from any device, with the desktop version or the Metricool app. Free forever. No trial period. Save time scheduling, analyzing, and managing your social media and ad content.", "https://metricool.com/"],
  ["Calendly", "Calendly is a scheduling automation platform that eliminates the back-and-forth emails to find the perfect time for meetings.", "https://calendly.com/"],
  ["Doodle", "Professional scheduling made easy. Doodle is the fastest and easiest way to schedule anything — from meetings to the next great collaboration. Create a Doodle. Trusted by 70,000+ companies.", "https://doodle.com/"],
  ["Acuity Scheduling", "Acuity Scheduling is customizable appointment scheduling software made easy, automating your workflows, payments, and bookings.", "https://acuityscheduling.com/"],
  ["Setmore", "You can create unlimited appointments, automate confirmations and reminders, add staff, host video meetings, and store customer details. Explore our plans to learn the differences between Setmore Free, Pro, and Team.", "https://www.setmore.com/"],
  ["Appointy", "Our online appointment scheduling software has everything you need to grow & manage your business in one easy-to-use and powerful user interface.", "https://www.appointy.com/"],
  ["YouCanBook.me", "Friction-free scheduling that saves you time. No more back and forth to find a time to meet. Let clients book you when it suits them with a booking site that’s on duty 24/7 for scheduling, cancelling and rescheduling.", "https://youcanbook.me/"],
  ["TimeTrade", "We would like to show you a description here but the site won’t allow us.", "https://www.timetrade.com/"],
  ["SimplyBook.me", "Access all your bookings, make new bookings, create clients, etc. by a simplified version of the main system, intended for users on the road. Get notifications of new bookings, and reminders for upcoming appointments.", "https://simplybook.me/"],
  ["Bookafy", "Effortless online appointment scheduling software with our cutting-edge booking app. Streamlined booking process, intelligent scheduling, and sales automation.", "https://bookafy.com/"],
  ["Schedulicity", "Book all services and classes online instantly! Find more salon, spa, fitness, and health professionals near you. Discover local deals and support small businesses with us!", "https://www.schedulicity.com/"],
  ["ViralFindr", "ViralFindr is a simpler way to find the best performing content on Instagram. Use it for inspiration to create your own or download to ANY device and repost to your feed. Register a free account.", "https://viralfindr.com/"],
  ["TokAudit", "#1 TikTok Extension TikTok Creators & Brands. The only TikTok Marketing Tool you'll ever need! Get started for free. Cut down your research and guesswork in seconds.", "https://tokaudit.io/"],
  ["Podsqueeze", "Transcribe and write show notes for your podcast 20x faster. Save up to 100% of manual work. Automatically transcribe your podcast with state-of-the-art accuracy and speaker labeling, and generate complete show notes and social media posts in your own voice.", "https://podsqueeze.com/"],
  ["Podcastle", "Create an entire podcast in seconds by using vibrant AI voices to tell your story; or clone your own voice and simply type in the script!", "https://podcastle.ai/"],
  ["Big Room TV", "Big Room uses AI to automatically convert any landscape video into a vertical format, ready to post on TikTok, IG Reels or Stories, and YouTube Shorts.", "bigroom.tv"],
  ["Runway.ml", "Runway is an applied AI research company shaping the next era of art, entertainment, and human creativity.", "https://runway.ml/"],
  ["Unscreen", "100% Automatically, without a single click. No need to pick pixels, select colors, paint masks or move paths: Unscreen analyzes your video 100% automatically and generates a high-quality result. Simple, good.", "https://unscreen.com/"],
  ["Uizard", "This just changes the game for product teams. Visualize product ideas fast and easy with AI. UI design for apps, websites, and desktop software in minutes. If you can use Google Slides or PowerPoint, you can use Uizard!", "https://uizard.io/"],
  ["Eluna.ai", "Unleash your creativity with a variety of models and tools that help you create unique and stunning images. Our AI-powered image generation tool allows you to experiment with different styles to bring your ideas to life.", "https://eluna.ai/"],
  ["Thumbly", "With Thumbly’s Al-powered solutions for creators, you have a powerful ally at your disposal. Join the Thumbnail Revolution with our Al Clickbait Thumbnail Generator. Experience high-quality, custom thumbnails designed to enhance your YouTube success.", "https://thumbly.ai/"],
  ["Vondy", "Browse and create AI-powered digital services. Millions of deliverables, instant generations, thousands of services.", "https://www.vondy.com/"],
  ["VEED.IO", "Free AI video editor with text to video, avatars, auto-subtitles, voice translations and more. Record, edit and share your videos online with VEED.", "https://veed.io"],
  ["Ripl", "Ripl enables small businesses to create professional-looking animated videos, collages, slideshows, and layered static image posts in minutes, as well as schedule or post instantly to Facebook, Instagram, LinkedIn, Twitter, and YouTube.", "https://app.ripl.com"],
  ["Durable", "Get your business online in 30 seconds with the #1 AI website builder and marketing platform. Effortlessly publish a professional website, no coding skills required. Grow your revenue with built-in SEO, marketing tools, and review automation.", "https://durable.co/ai-website-builder"],
  ["Synthesia", "Create AI videos from text with AI video generator. Get most advanced AI avatars and voiceovers in 140+ languages. Try free AI video generator today!", "https://synthesia.io"],
  ["AITools.fyi", "Find the best AI tools to help you build your next awesome project faster and easier. We have curated the best AI tools for you to use in your next project.", "https://aitools.fyi/"],
  ["Substack", "A Substack combines a website, blog, podcast, video tools, payment system, and customer support team — all integrated seamlessly in a simple interface. We handle the admin, billing, and tech so you can focus on making your best work.", "https://substack.com/"],
  ["Patreon", "Log in or sign up. Need help signing in? Patreon is empowering a new generation of creators. Support and engage with artists and creators as they live out their passions!", "https://www.patreon.com/"],
  ["Memberful", "Best-in-class membership software for independent creators, publishers, educators, podcasters, and more. Memberful has everything you need to run a membership program.", "https://memberful.com/"],
  ["OnlyFans", "OnlyFans is the social platform revolutionizing creator and fan connections. The site is inclusive of artists and content creators from all genres and allows them to monetize their content while developing authentic relationships with their fanbase.", "https://www.onlyfans.com/"],
  ["Ghost", "Ghost is a powerful app for professional publishers to create, share, and grow a business around their content. It comes with modern tools to build a website, publish content, send newsletters & offer paid subscriptions to members.", "https://ghost.org/"],
  ["Gumroad", "Browse over 1.6 million free and premium digital products in education, tech, design, and more categories from Gumroad creators and online entrepreneurs.", "https://gumroad.com/"],
  ["Kajabi", "Courses. Monetize your knowledge and experience with courses. Get started instantly with our AI course builder. Memberships. Build branded and engaging subscription memberships that earn you recurring revenue. Podcasts. Unlock your podcast’s full potential.", "https://kajabi.com/"],
  ["Teachable", "Teachable is a no-code platform for creators who want to build more impactful businesses through courses, coaching, and digital downloads.", "https://teachable.com/"],
  ["Thinkific", "Create & Sell Courses, Memberships, Digital Products, and Community Access: Thinkific. Unite with a powerful collective of creators on a mission to share meaningful digital learning experiences to the world.", "https://www.thinkific.com/"],
  ["Mighty Networks", "With people magic, your online courses, content, memberships, challenges, and events become much more engaging and profitable. Build a $1M Community.", "https://www.mightynetworks.com/"],
  ["Podia", "Get a free website, manage your email marketing, host a community for free, or sell courses and digital products. Podia is an all-in-one platform that lets you start and grow a business.", "https://www.podia.com/"],
  ["ConvertKit", "Set up your paid product. Let’s work towards that first sale with a commerce tool that helps you market your work, collect payments, and deliver your products.", "https://convertkit.com/commerce"],
  ["Cocoon", "A seamless employee leave experience when it matters most. Unlike traditional leave providers, our intuitive tools give employees the power to privately plan a leave in minutes, easily file claims, and accurately track their pay.", "https://www.cocoon.com/"],
  ["Mighty Networks", "Login to your Mighty Network directly as a Host or a member. Don’t already have a Mighty Network? Create your own community for free.", "https://www.mightynetworks.com/mighty-app"],
  ["Arcade", "Build demos that drive action. Get started for free. Talk to sales. Arcade is an interactive demo platform that allows teams to create effortlessly beautiful demos in minutes.", "https://www.arcade.software/"],
  ["Viral Ninja", "Viral Ninja is an all-in-one e-commerce solution that helps you find, track, and optimize products for your online store. It offers tools like Product Database, Product Tracker, Product Insights, Store Scraper, and Supplier Database to empower your business with data-driven insights and verified suppliers.", "Viralninja.io"],
  ["AKOOL", "AKOOL is a breakthrough Generative AI platform for personalized visual marketing and advertising.", "akool.com"],
  ["B2B Rocket", "Experience The Future of Unlimited Digital Workforce & Prospecting Automation With B2B Rocket's AI Agents.", "b2brocket.ai"],
  ["Creative AI", "Product design made delightful with AI magic.", "creative.ai"]
];
