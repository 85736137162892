// MODIFICATIONS: Made all text in the search results lavender.
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { books } from './Data';

const itemsPerPage = 10;

function ResultsPage() {
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredList, setFilteredList] = useState([]);
  const [error, setError] = useState(null); // Error state
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const searchTerm = searchParams.get('search') || '';

  useEffect(() => {
    try {
      const tokens = searchTerm
        .toLowerCase()
        .split(' ')
        .filter((token) => token.trim() !== '');

      if (tokens.length) {
        const searchTermRegex = new RegExp(tokens.join('|'), 'gim');
        const filtered = books.filter((book) => {
          const bookString = book.join(' ').toLowerCase().trim();
          return bookString.match(searchTermRegex);
        });
        setFilteredList(filtered);
      } else {
        setFilteredList([]);
      }
    } catch (err) {
      setError('Failed to fetch results'); // Error handling
    }
  }, [searchTerm]);

  if (error) {
    return <div className="error">{error}</div>; // Error display
  }

  const totalPages = Math.ceil(filteredList.length / itemsPerPage);

  const goToPage = (page) => {
    setCurrentPage(page);
  };

  const handleSearchAgain = () => {
    navigate('/');
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, filteredList.length);

  return (
    <div>
      <div className="searchBox">
        <input
          type="search"
          placeholder="Search books or type the title"
          id="input"
          value={searchTerm}
          onChange={handleSearchAgain}
        />
        <img
          src="images/magni.png"
          id="search-icon"
          alt="Search"
          width="20"
          height="20"
          onClick={handleSearchAgain}
          style={{ cursor: 'pointer' }}
        />
      </div>
      <div id="app" style={{ margin: '20px', color: 'lavender' }}> {/* MODIFICATION: Inline lavender color */}
        <ul>
          {filteredList.slice(startIndex, endIndex).map((book, index) => (
            <li key={index}>
              <strong>{startIndex + index + 1}. </strong>
              {book.map((detail, i) => (
                <a key={i} href={book[2].startsWith('http') ? book[2] : 'http://' + book[2]} target="_blank" rel="noopener noreferrer" style={{ color: 'light purple' }}>
                  <span className={i === 0 ? "bold-line" : ""}>{detail}</span>
                </a>
              )).reduce((prev, curr) => [prev, <br key={`br-${index}`} />, curr])}
            </li>
          ))}
        </ul>
      </div>
      {totalPages > 1 && (
        <div className="pagination" id="pagination">
          {Array.from({ length: totalPages }, (_, i) => (
            <button
              key={i + 1}
              className={i + 1 === currentPage ? 'active' : ''}
              onClick={() => goToPage(i + 1)}
            >
              {i + 1}
            </button>
          ))}
        </div>
      )}
    </div>
  );
}

export default ResultsPage;
