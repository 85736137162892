import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function SearchPage() {
  const [inputValue, setInputValue] = useState('');
  const navigate = useNavigate();

  const handleSearchClick = () => {
    if (inputValue.trim()) {
      navigate(`/results?search=${encodeURIComponent(inputValue.trim())}`);
    }
  };

  return (
    <div className="scorpion">
      <div className="hero">
        <div className="logo-container">
          <img src="images/border.png" id="scorp-img" alt="Logo" width="100" height="100" />
        </div>
        <div className="searchBox">
          <input
            type="search"
            placeholder="Search for Ai here!"
            id="input"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
          <img
            src="images/magni.png"
            id="search-icon"
            alt="Search"
            width="20"
            height="20"
            onClick={handleSearchClick}
            style={{ cursor: 'pointer' }}
          />
        </div>
      </div>
    </div>
  );
}

export default SearchPage;
